.nav {
  margin:0;
  padding:0;
}

.NavBar-Container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 12vh;
  background-color: #1CAFA9;
  list-style-type: none;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 0;
}

.NavBar-Container > li {
  flex: 1;
  font-size: 17px;
  font-family: "Futura";
  color: #E5E551;
  letter-spacing: 2px;
  text-align: center;
  max-width: 175px;
  cursor: pointer;
}

.NavBar-Container > li a {
  text-decoration: none;
  color: #E5E551;
}

.NavBar-Container > li a:hover {
  color: #FFFFFF;
}

.NavBar-Container > li:hover {
  color: #FFFFFF;
}

#Name {
  color: #FFFFFF;
  font-size: 28px;
  text-align: center;
  max-width: 300px;
  cursor: default;
}

#Toggle {
  display: none;
}

.DropDown {
  display: none;
}
  


@media all and (max-width: 1400px) {
  .NavBar-Container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: flex-start;
    height: 24vh;
  }

  #Name {
    order: -1;
    flex-basis: 100%;
    max-width: 100%;
    align-content: center;
    height: 7vh;
    margin-top: 8vh;
    margin-bottom: 3vh;
    text-align: center;
  }

  #Toggle {
    display: none;
  }
}

@media all and (max-width: 640px) {
  .NavBar-Container {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    align-content: flex-start;
    height: 10vh;
  }

  .NavBar-Container > li {
    display: none;
  }

  #Toggle {
    display: block;
    max-width: 10%;
    flex-basis: 10%;
    font-size: 15px;
  }

  #Name {
    display: block;
    order: 1;
    flex-basis: 90%;
    max-width: 90%;
    align-content: center;
    height: 5vh;
    margin-top: 3vh;
    margin-bottom: 2vh;
    text-align: center;
    font-size: 24px;
  }

  .DropDown {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 12vh;
    background-color: #1CAFA9;
    list-style-type: none;
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;
  }

  .DropDown > li {
    flex: 1;
    font-size: 14px;
    font-family: "Futura";
    color: #E5E551;
    letter-spacing: 2px;
    text-align: center;
    max-width: 175px;
    cursor: pointer;
  }

  .DropDown > li:hover {
    color: #FFFFFF;
  }

  .DropDown li a {
    text-decoration: none;
    color: #E5E551;
  }

  .DropDown li a:hover {
    color: #FFFFFF;
  }

}