.Section {
	height: 524px;
	background-color: #1CAFA9;
	margin-right: none;
	width: 100%;
}

.Slogan {
	font-family: "Futura";
	font-size: 4.5em;
	color: #FFFFFF;
	padding-left: 159px;
	padding-top: 100px;
	cursor: default;
}

.LinkedInLogo {
	height: 60px;
	width: 60px;
	padding-left: 160px;
	padding-top: 10px;
	padding-right: 5px;
}

.YouTubeLogo {
	height: 60px;
	width: 60px;
	padding-left: 5px;
	padding-right: 5px;
}

.GitHubLogo {
	height: 60px;
	width: 60px;
	padding-left: 5px;
	padding-right: 5px;
}

.EmailLogo {
	height: 60px;
	width: 60px;
	padding-left: 5px;

}


@media all and (max-width: 1400px) {
	.Section {
		height: 450px;
	}

	.Slogan {
		padding-top: 20px;
	}
}

@media all and (max-width: 640px) {
	.Section {
		height: 430px;
	}

	.Slogan {
		font-size: 3em;
		padding-left: 50px;
		padding-top: 100px;
		line-height: 70px;
	}

	.LinkedInLogo {
		height: 50px;
		width: 50px;
		padding-left: 50px;
		padding-top: 10px;
		padding-right: 5px;
	}

	.YouTubeLogo {
		height: 50px;
		width: 50px;
		padding-left: 5px;
		padding-right: 5px;
	}

	.GitHubLogo {
		height: 50px;
		width: 50px;
		padding-left: 5px;
		padding-right: 5px;
	}

	.EmailLogo {
		height: 50px;
		width: 50px;
		padding-left: 5px;

	}
}