.About {
	display: flex;
	flex-direction: column;
	background-color: #FFFFFF;
	margin-bottom: 20px;
}

.AboutHeading {
	float: left;
	font-family: "Futura";
	font-size: 2.7em;
	padding-top: 0px;
	margin-left: 150px;
	margin-top: 60px;
	cursor: default;
}

.AboutParagraph {
	float: left;
	margin-left: 20%;
	margin-top: 65px;
	margin-right: 20%;
	cursor: default;
}

.AboutParagraph p {
	font-family: "Futura";
	font-size: 1.2em;
}
