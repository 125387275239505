html {
  scroll-behavior: smooth;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow: auto;
}

body {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
}

/*
* {
  background: #000 !important;
  color: #0f0 !important;
  outline: solid #f00 1px !important;
}
*/