.title {
	font-family: "Futura";
	font-size: 2.7em;
	margin-top: 60px;
	margin-left: 150px;
	height: 60px;
	cursor: default;
}

.body {
	float: right;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
}

.container {
	display: flex;
	flex-direction: column;
}
