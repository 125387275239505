/* Design inspiration:  https://dribbble.com/shots/1978243-Latest-News */

$grey: #373446;

body, html {
  font-family: 'Montserrat', sans-serif;

}

.card {
  width:280px;
  display: block;
  margin: 60px;
  box-shadow: 10px 5px 40px 20px darken(#373446, 2%);
  transition: .25s;
  
  &:hover {
    box-shadow: 10px 5px 40px 20px darken(#373446, 15%);
    cursor: pointer;
    
    .button-primary {
      transform: translate(10px, 0);
    }
  }
}

.card-header {
  height: 150px;
  width: 100%;
  padding: 15px;
  width:100%;
  background-size:cover;
  color:#fff;
}

.card-header--title {
  text-transform: uppercase;
  margin: 0;
}

.card-body {
  padding: 15px;
  background-color:#fff;
  width:100%;
}

.date {
  font-size: 11px;
  font-weight: 600;
  color: grey;
}

.body-content {
  padding-bottom: 40px;
  font-size: 13px;
  line-height: 1.8;
}

.button-primary {
  border: none;
  box-shadow: none;
  font-family: inherit;
  background-color:transparent;
  color: $grey;
  font-size: 15px;
  transition:.25s;
  cursor: pointer;
  
  i{
    padding-right:4px;
  }
}


.designer-link {
  color:#fff;
  text-align:center;
  font-size: 12px;
  text-decoration: none;
  display:block;
}
